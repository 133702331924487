import { $axios } from '@/utils/nuxt-instance'

export default {
	// [GET] Define se o recaptcha será validado/apresentado
  exibeReCaptcha: async () => {
		return await $axios.get('/acessos/exibe-recaptcha')
  },
  // [POST] Autenticação de login e senha
  login: async (request) => {
    return await $axios.post('/acessos/login', request)
  },
  // [POST] Desloga usuário
  logout: async () => {
    return await $axios.post('/acessos/logout')
  },
  // [POST] Inicia o processo de recuperação de senha
  recuperaSenha: async (request) => {
    return await $axios.post('/acessos/senha/recuperar', request)
  },
  // [POST] Redefine senha de acesso para o usuário
  redefineSenha: async (request) => {
    return await $axios.post('/acessos/senha/redefinir', request)
  },
  // [POST] Define se o cliente possui permissão para entrar na rota
  permiteRota: async (request) => {
    return await $axios.post('/acessos/rota-permissao', request)
  },
  // [POST] Valida o token enviado para o usuário
  validaToken: async (request) => {
    return await $axios.post('/acessos/token/validar', request)
  }
}
