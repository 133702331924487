import { $axios } from '@/utils/nuxt-instance';

export default {
  // POST Simular
	simular: async (request) => {
		return await $axios.post('/simulacoes', request);
	},
	simularAtualizado: async (request) => {
		return await $axios.put('/simulacoes', request)
	},
	simularPropostas: async (request) => {
		return await $axios.post('/simulacoes/propostas', request)
	},
	simularPortabilidade: async (request) => {
		return await $axios.post('/simulacoes/portabilidade', request)
	},
};
