import { $axios } from '@/utils/nuxt-instance';

export default {
	// GET Naturezas
  naturezas: async () => {
		return await $axios.get('/convenios/naturezas');
	},
	// GET Naturezas - UF
  naturezasUf: async (codNatureza) => {
		return await $axios.get(`/convenios/naturezas/${codNatureza}/uf`);
	},
  // GET Naturezas - Cidades
  naturezasCidades: async (codNatureza, codUf) => {
		return await $axios.get(`/convenios/naturezas/${codNatureza}/uf/${codUf}/cidades`);
	},
	// GET Naturezas - Convênios
  naturezasConvenios: async (codNatureza, codUf, codCidade) => {
		return await $axios.get(`/convenios/naturezas/${codNatureza}/uf/${codUf}/cidades/${codCidade}/convenios`);
	},
	// GET Naturezas - Convênios Nacionais
	naturezasConveniosNacionais: async (codNaturezas) => {
		return await $axios.get(`/convenios/naturezas/${codNaturezas}`);
	},
  // GET Convênios
	convenios: async () => {
		return await $axios.get('/convenios');
	},
  // GET Convênios - Campos
  conveniosCampos: async (codConvenio) => {
		return await $axios.get(`/convenios/${codConvenio}/campos`);
	},
	// GET Convênios - Valida campos
  conveniosValidaCampos: async (codConvenio, codCampo, valor) => {
		return await $axios.get(`/convenios/${codConvenio}/campos/${codCampo}/valor/${valor}`);
	},
  // GET Convênios - Orgãos Conveniados
  conveniosOrgaos: async (codConvenio) => {
		return await $axios.get(`/convenios/${codConvenio}/orgaos-conveniados`);
	},
	// GET Convênios - Regimes Contratação
  conveniosRegimes: async () => {
		return await $axios.get(`/convenios/regimes-contratacao`);
	},
	// GET Convênios - Voucher
	conveniosVoucher: async (codConvenio, codVoucher) => {
		return await $axios.get(`/convenios/${codConvenio}/voucher/${codVoucher}`);
	},
};
