export default {
  cookiesAceite: async (request) => {
    const isLocalHost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

    const baseUrl = isLocalHost
      ? 'https://ws0001.laboratorio.corp/Consignado/Barramento/api/v2'
      : '/minha-conta/api/gateway?u=/v2'

    const endpoint = '/usuarios/cookie-aceite'

    const fullUrl = `${baseUrl}${endpoint}`

    try {
      const response = await fetch(fullUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      return { status: response.status, data }
    } catch (error) {
      throw new Error(error)
    }
  },
}
