import { $axios } from '@/utils/nuxt-instance'

export default {
	dadosbasicos: async (request) => {
		return await $axios.post('/usuarios/dados-basicos', request);
	},
	senhaCriar: async (request) => {
		return await $axios.post('usuarios/senha/criar', request);
	},
  enviarTokenEmail: async () => {
		return await $axios.post('/usuarios/token-email/enviar');
	},
  validarTokenEmail: async (request) => {
		return await $axios.post('/usuarios/token-email/validar', request);
	},
  enviarTokenCelular: async () => {
		return await $axios.post('/usuarios/token-celular/enviar');
	},
  validarTokenCelular: async (request) => {
		return await $axios.post('/usuarios/token-celular/validar', request);
	},
};
