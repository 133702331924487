import convenios from './convenios'
import simulacao from './simulacao'
import usuarios from './usuarios'
import cookies from './cookies'
import acessos from './acessos'

export default {
  convenios,
  simulacao,
  usuarios,
  cookies,
  acessos
}
